/* Currently there seems to be an issue with pointer-events in chrome disabling the scroll */

.container {
  --teaser-basic-text-align: center;
  --button-wrapper-justify-content: center;
  --_font-family-title: var(--cosmos-font-family-title);
  --_background-color: var(
    --scheme-secondary,
    var(--cosmos-color-surface-solid-light)
  );
  --_can-shadow: var(--scheme-can-shadow-rgb, var(--can-hero-shadow-color-rgb));

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background-color: var(--_background-color);
  padding-inline: 16px;
  height: 100svh;
  min-height: 700px;
  overflow: hidden;
  color: var(--color-text-light);
}

.inner {
  display: grid;
  position: relative;
  grid-template-rows: auto 1fr;
  flex-direction: column;
  z-index: 2;
  height: 100svh;
  min-height: 400px;
}

.expander {
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 500ms linear;
}

.expander-inner {
  overflow: hidden;
}

.expander-inner-padding {
  padding-block: var(--spacing-xxxx-loose);
}

.content-wrap {
  margin-inline: auto;
  max-width: 866px;
}

/* Avoid hydration errors */
.cta-button {
  &[data-breakpoint="mobile"] {
    display: block;
  }

  &[data-breakpoint="tablet"] {
    display: none;
  }

  @media (width >= 1000px) {
    &[data-breakpoint="mobile"] {
      display: none;
    }

    &[data-breakpoint="tablet"] {
      display: block;
    }
  }
}

.can-wrap {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  justify-self: stretch;
}

/*
.can-wrap-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
*/

.picture {
  display: block;
  margin-inline: auto;
  width: 220px;
}

.image {
  transform-origin: top center;
  filter: drop-shadow(
    var(--can-hero-shadow-values)
      rgb(var(--_can-shadow) / var(--can-hero-shadow-alpha))
  );
  will-change: filter;
  width: 100%;
  height: auto;
}

.banderole-text-wrap {
  display: flex; /* help with inner text overflow */
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
  width: 100%;
  overflow: hidden;
}

.banderole-text-wrap-inner {
  opacity: 1;
}

/* 1. Cosmos title has it's own internal --cosmos-title-font-family so we need to set it elsewhere */
.banderole-text {
  --cosmos-title-font-size: var(--cosmos-type-xxxx-large-font-size-desktop);
  --cosmos-title-font-weight: var(--cosmos-font-weight-bold);
  --cosmos-title-color: transparent;
  --cosmos-title-font-family: var(--_font-family-title); /* 1 */

  overflow: hidden;
  white-space: nowrap;
  -webkit-text-stroke-width: 2px; /* adds a visual 2px stroke */
  -webkit-text-stroke-color: var(--cosmos-color-text-dark);
  paint-order: stroke fill;

  &[data-appearance="dark"] {
    -webkit-text-stroke-color: var(--cosmos-color-text-dark);
  }

  &[data-appearance="light"] {
    -webkit-text-stroke-color: var(--cosmos-color-text-light);
  }
}

.button-wrapper {
  --button-wrapper-flex-direction: column;

  @media (width >= 650px) {
    --button-wrapper-flex-direction: unset;
  }
}
