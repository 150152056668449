.container {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-x-tight);
  padding-inline: var(--spacing-normal);
  width: 100%;

  @media (width >= 650px) {
    padding-inline: var(--spacing-xx-loose);
  }

  @media (width >= 1000px) {
    flex-direction: row;
    padding-inline: initial;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: var(--radius-x-large);
  background: var(--color-surface-glass-dark-10);
  padding: var(--spacing-normal);

  @media (width >= 650px) {
    flex-direction: row;
  }
}

.icon {
  --cosmos-icon-size: 18px;
  --_icon-background-color: rgb(
    223,
    225,
    226
  ); /* this equals the solid version of suface-glass-dark-10 on a white background */
  position: relative;
  margin-left: 40px; /* magic number */
  border-top-right-radius: var(--radius-x-large);
  border-top-left-radius: var(--radius-x-large);
  background: var(--color-surface-glass-dark-10);
  padding: var(--spacing-xx-tight);
  width: var(--box-information-highlight-icon-size);
  height: var(--box-information-highlight-icon-size);
  color: var(--color-primary);
  line-height: normal;

  &:before {
    /* use pseudo element to add a curved wedge above the icon */
    position: absolute;
    right: calc(-1 * var(--box-information-highlight-icon-size));
    bottom: 0;
    transform: rotate(90deg);
    box-shadow: 13px 0px 0 0 var(--_icon-background-color);
    border-bottom-right-radius: 50%;

    background-color: transparent;
    width: var(--box-information-highlight-icon-size);
    height: var(--box-information-highlight-icon-size);
    content: "";
  }
  &:after {
    /* use pseudo element to add a curved wedge below the icon */
    position: absolute;
    bottom: 0;
    left: calc(-1 * var(--box-information-highlight-icon-size));
    transform: rotate(90deg);
    box-shadow: 13px 0px 0 0 var(--_icon-background-color);
    border-top-right-radius: 50%;
    background-color: transparent;

    width: var(--box-information-highlight-icon-size);
    height: var(--box-information-highlight-icon-size);
    content: "";
  }

  @media (width >= 1000px) {
    margin-top: 60px; /* magic number */
    margin-left: initial;
    border-top-right-radius: initial;
    border-top-left-radius: var(--radius-x-large);
    border-bottom-left-radius: var(--radius-x-large);

    &:before {
      top: calc(-1 * var(--box-information-highlight-icon-size));
      left: 0;
      /* use pseudo element to add a curved wedge left of the icon */
      transform: initial;
      box-shadow: 13px 0 0 0 var(--_icon-background-color);
      border-bottom-right-radius: 50%;
    }

    &:after {
      /* use pseudo element to add a curved wedge right of the icon */
      top: var(--box-information-highlight-icon-size);
      left: 0;
      transform: initial;
      box-shadow: 13px 0px 0 0 var(--_icon-background-color);
      border-top-right-radius: 50%;
    }
  }
}
