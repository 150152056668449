.container {
  margin-bottom: var(--spacing-bottom);
  padding-inline: var(--spacing-sides);
}

.inner {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: var(--spacing-xxxx-loose);
  row-gap: var(--spacing-tight);
  container-type: inline-size;
  margin-inline: auto;
  max-width: var(--max-width-base);

  @media (width >=1000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
