.container {
  display: flex;
}

.card {
  --cosmos-button-min-width: 100%;
  --cosmos-button-border-radius: 12px; /* magic number */
  --focus-offset: var(--cosmos-button-border-width, 1px);
  --focus-radius: var(--cosmos-button-border-radius);

  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: var(--elevation-1-above);
  border-width: var(--cosmos-button-border-width, 1px);
  border-style: solid;
  border-color: var(--cosmos-button-border-color, transparent);
  border-radius: var(--cosmos-button-border-radius, 9999px);
  background-color: color-mix(
    in srgb,
    var(--color-surface-solid-light-lighter),
    transparent 50%
  );
  padding-inline: 16px;
  padding-block: 14px; /* -2 for border width */
  width: 100%;
  max-width: 100%;
  color: var(--color-text-dark);
  text-align: left;
  text-decoration: none;

  &:hover {
    box-shadow: var(--elevation-2-above);
    background-color: var(--color-surface-solid-light-lighter);
  }
}
