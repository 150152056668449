.container {
  position: relative;
  margin-top: var(--spacing-top);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);
  overflow: hidden;

  @media (width >= 1000px) {
    margin-bottom: var(--spacing-bottom);
    height: unset;
    overflow: unset;
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.main {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: var(--spacing-xx-loose);

  @media (width >= 1000px) {
    grid-template-columns: minmax(256px, 1fr) minmax(auto, 760px);
  }

  &:has([data-category-kind="company"]) {
    /* TODO: potentially revisit the grid styles for this component */
    @media (width >= 1250px) {
      grid-template-columns: minmax(333px, 1fr) minmax(auto, 666px);
    }
  }
}

.main-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline-start: auto;
  margin-bottom: var(--spacing-xxx-loose);
  width: 100%;

  @media (width >= 650px) {
    margin-bottom: var(--spacing-xxx-loose);
  }

  @media (width >= 1000px) {
    grid-row: 1 / 2;
    grid-column: 2/-1;
    margin-top: initial;
    margin-bottom: initial;
    margin-block: var(--spacing-xxxx-loose);
    min-height: calc(662px - (var(--spacing-xxxx-loose) * 2));
  }
}

.header {
  display: flex;
  position: relative; /* for dietary marks */
  flex-direction: column;
  text-align: center;

  @media (width >= 1000px) {
    margin-bottom: var(--spacing-loose);
    text-align: initial;
  }
}

.kicker {
  --kicker-color: var(--color-text-dark-subtle);

  margin-bottom: var(--spacing-normal);
}

.featured {
  @media (width >= 1000px) {
    display: flex;
    grid-row: 2 / 3;
    grid-column: 2/-1;
    flex-direction: column;
    justify-content: center;
    min-height: 662px; /* var(--can-height) */
  }
}

.image {
  position: relative; /* for .img */
  /* cut off bottom part of the image */
  margin-bottom: -120px; /* magic number - visually positioned */

  @media (width >= 650px) {
    margin-bottom: -160px; /* magic number - visually positioned */
  }

  @media (width >= 1000px) {
    display: initial;
    grid-row: 1 / span 2;
    grid-column: 1/2;
    margin-bottom: initial;
  }

  &[data-kind="index"] {
    display: initial;
  }

  &[data-category-kind="company"] {
    align-content: center;
    margin-bottom: var(--spacing-xxx-loose);

    img {
      filter: unset;
      border-radius: var(--radius-12px);
      max-width: calc(375px - (var(--spacing-sides) * 2)); /* magic number */
      height: 100%;
      max-height: 512px; /* magic number */
      object-fit: cover;

      @media (width >= 650px) {
        max-width: 438px; /* magic number */
      }

      @media (width >= 1000px) {
        max-width: calc(375px - (var(--spacing-sides) * 2)); /* magic number */
      }

      @media (width >= 1250px) {
        max-width: 438px; /* magic number */
      }
    }
  }
}

.img {
  display: block;

  filter: drop-shadow(
    var(--can-tiny-shadow-values)
      rgba(var(--can-tiny-shadow-color-rgb) / var(--can-tiny-shadow-alpha))
  );
  will-change: filter; /* Stop dropshadow Safari flicker */
  margin-inline: auto;
  /* take ratio from smallest viewpoint until it reaches 283px (Figma magic number)*/
  max-width: min(
    calc(100vw * (175 / 375)),
    283px
  ); /* magic number - visually positioned */
  height: auto;
  max-height: 668px; /* magic number */
}

.content {
  margin-top: var(--spacing-loose);
  text-align: center;

  @media (width >= 1000px) {
    text-align: initial;
  }
}

.prose {
  --cosmos-text-font-size: var(--fluid-type-medium);
  --cosmos-text-line-height: var(--type-medium-line-height);

  font-size: var(--cosmos-text-font-size);
  line-height: var(--cosmos-text-line-height);

  @media (width >= 1000px) {
    font-size: var(--fluid-type-large);
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--spacing-x-tight);
  margin-top: var(--spacing-loose);

  @media (width >= 650px) {
    margin-top: var(--spacing-x-loose);
  }

  @media (width >= 1000px) {
    justify-content: flex-start;
    margin-top: var(--spacing-xx-loose);
  }
}

.tag-image {
  border-radius: var(--radius-full);
  width: 32px;
  height: 32px;
}

.tag[size="large"] .tag-image-wrap {
  margin-inline-start: -16px;
}

.tag[size="large"]::part(base) {
  background: red;
  padding-inline-end: 8px;
}

.tag[size="medium"] .tag-image-wrap {
  margin-inline-start: -12px;
  margin-inline-end: 2px; /* 8px, cosmos button text has a 6px left margin */
}

.tag[size="medium"]::part(base) {
  padding-inline-end: 16px;
}
