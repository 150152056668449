.container {
  justify-self: center;
  margin-bottom: var(--spacing-xx-loose);
  max-width: var(--box-information-highlight-desktop-width);

  /* Use proportions as shown in the 1440px width figma design */
  @media (width >= 1000px) {
    & [data-widget-layout="inner"] {
      width: calc(
        var(--box-information-highlight-desktop-width) -
          var(--box-information-highlight-icon-size)
      );
    }
  }
}

.title {
  flex: 1;
  align-self: center;

  @media (width >= 650px) {
    align-self: initial;
  }
}

.image {
  align-self: center;
  mix-blend-mode: multiply;
  filter: grayscale(1) brightness(1.2);
  max-width: 316px; /* magic number, see figma design */

  @media (width >= 650px) {
    width: 50%;
  }
}
