.nav {
  display: flex;
  max-width: 100%;
}

/*
 1: not actually used by Cosmos variable type 
 2: remove list style */
.list {
  --cosmos-text-line-height: 1;
  --cosmos-text-font-size: var(--fluid-type-small);
  --cosmos-text-font-weight: 500; /* 1 */

  display: flex;
  align-items: center;
  margin-block: 0; /* 2 */
  border-radius: var(--radius-full);
  background-color: var(--cosmos-color-surface-glass-dark-10);
  padding-inline: 12px;
  padding-block: 4px;
  max-width: 100%;
  font-weight: var(--cosmos-text-font-weight);
  font-size: var(--cosmos-text-font-size);
  line-height: var(--cosmos-text-line-height);
  list-style: none; /* 2 */

  @media (width >= 1000px) {
    --cosmos-text-font-size: var(--fluid-type-medium);
  }
}

.list-item {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    overflow: hidden; /* for ellipsis overflow */
  }
}

.list-item-seperator {
  flex-shrink: 0;
  margin-inline: 0.25ch; /* magic number, seperator svg has space too */
  width: auto;
  height: 1em;
  color: var(--color-text-dark-subtle);
}

.breadcrumb {
  display: flex;
  align-items: center;
  overflow: inherit; /* for ellipsis overflow */
}

a.link {
  color: var(--color-text-dark-subtle);
  text-decoration-line: none;
  text-decoration-thickness: var(--link-text-decoration-thickness);
  text-underline-offset: var(--link-text-underline-offset);

  &:is(:hover, :focus-within) {
    text-decoration-line: underline;
  }

  &:not(:focus-within, :hover) {
    text-decoration-color: var(--link-text-decoration-color-hover);
  }
}

.link {
  display: flex;
  align-items: center;
  overflow: inherit; /* for ellipsis overflow */
  color: var(--color-text-dark-subtle);
}

.link-text {
  max-width: 50ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link[aria-current="page"] {
  color: var(--color-text-dark);
}

.link-image {
  margin-inline-start: -8px;
  margin-inline-end: 0.5ch;
  border-radius: var(--radius-full);
  background: white;
  width: 24px;
  height: 24px;
}
